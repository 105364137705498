import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0,4];

export const dictionary = {
		"/": [~5],
		"/app/contact": [6],
		"/app/dashboard": [7],
		"/app/experiments": [~8],
		"/app/experiments/[experiment_id]": [~9],
		"/app/profile": [~10],
		"/app/profile/billing": [~11,[2]],
		"/app/profile/billing/change": [~12,[2]],
		"/app/profile/billing/success": [~13,[2]],
		"/app/profile/invoices": [~14,[3]],
		"/app/projects": [~15],
		"/app/projects/[project_id]": [~16],
		"/app/public/[experiment_id]": [~17],
		"/app/public/[experiment_id]/copy": [~18],
		"/dev": [19,[4]],
		"/dev/retreival": [20,[4]],
		"/dev/test": [~21,[4]],
		"/dev/test/errors": [~22,[4]],
		"/login": [~23]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';